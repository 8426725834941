<template>
    <section v-id="pageContent">
        <div class="navbar-padding">
            <div class="container-fluid mt-5">
                <div class="row">
                    <div class="col-md-4 text-white">
                        <h1>{{pageContent.name}}</h1>
                        <p v-html="pageContent.description"></p>
                    </div>
                    <div class="col-md-8" v-if="mainSlider">
                         <CarouselSection :slider="mainSlider" />
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-4"></div>
                    <div class="col-md-4 pt-5 pb-5 text-white text-center">
                        {{getPrice(pageContent).fprice}}
                        <button  @click="orderProduct(pageContent)" class="linkbutton w-100 mt-3">{{$store.state.translation.order}}</button>
                    </div>
                </div>
                <CarouselSection v-if="bottomSlider" :slider="bottomSlider" />
            </div>
            
            
        </div>
    </section>
</template>

<script>
/*import hp from '../components/HeroPlugin.vue';
import hpflow from '../components/HeroPluginFlow.vue';*/
import CarouselSection from "@/components/carouselSection";

export default {
    components:{
        //hp,hpflow,
        CarouselSection
    },
    data(){
        return{
            pageContent: null,
            homepageSlider: null
        }
    },
    computed:{
        mainSlider() {
            if (this.pageContent){
                if (this.pageContent.earthlings_product_image)
                {
                    var ret = {earthlings_slider_item: []}
                    this.pageContent.earthlings_product_image.forEach(it=>{
                        ret.earthlings_slider_item.push(it);
                    })
                    return ret;
                }
            }
            return false
        },
        bottomSlider() {
            if (this.pageContent){
                if (this.pageContent.earthlings_product_mood_image)
                {
                    var ret = {earthlings_slider_item: []}
                    this.pageContent.earthlings_product_mood_image.forEach(it=>{
                        ret.earthlings_slider_item.push(it);
                    })
                    return ret;
                }
            }
            return false
        }
    },
    methods:{
        async getCurrentPageContent(){
            let _this = this
            let slug = this.$route.params.name
            const content = await _this.getPageContent(slug)
            _this.pageContent = content[0]
            //_this.homepageSlider = _this.getProductSlider()
                
        },
        getPageContent(slug){
            let _this = this

            return new Promise((resolve, reject) =>{
                _this.get('earthlings_product', {
                    params: {
                        relations: 1,
                        where: [
                            ['where', 'status', '1'],
                            ['where', 'slug', slug]
                        ],
                        relations_params: {
                            relations: 1,
                            relations_params:{
                                earthlings_product_image: {
                                    order_by: [['sort_order','asc']]
                                },
                                earthlings_product_mood_image: {
                                    order_by: [['sort_order','asc']]
                                },
                            }
                        }
                    }
                    },
                ).then((resp) => {        
                    resolve(resp.data.earthlings_product)
                }).catch((err)=>{       
                    console.log(err) 
                    //404 page?
                    // if (this.$route.params.name != 'Gravity' || this.$route.params.name != 'gravity'  ){
                    //     this.$router.push({name: 'Home'})            
                    // }
                    
                    reject()
                })
            })
        },
        /*getProductSlider(){
            let slug = this.$route.params.name
            return this.pageContent.earthlings_page_block.find( item => item.name == `${slug}_slider`).earthlings_slider[0]
        }*/
        orderProduct(product){
            this.$store.state.selectedProduct = product;
            localStorage.setItem('cart',JSON.stringify(this.$store.state.selectedProduct ));
            this.$router.push({name: 'Cart_'+this.selectedLangCode })
        }
    },
    created(){
        this.getCurrentPageContent()
    }
}
</script>